import React from 'react'
import { Container, Row } from 'reactstrap'

import Layout from '../../layout/Layout'
import RoomsRoll from '../../components/RoomsRoll'

export const RoomsIndexPage = () => (
  <Layout>
    <Container>
      <Row>
        <div className='text-center mt-5 mb-5' style={{
          width: '100%'
        }}>
          <h1>Rooms</h1>
        </div>
        <div className='mb-5' style={{
          width: '100%'
        }}>
          <RoomsRoll />
        </div>
      </Row>
    </Container>
  </Layout>
)

export default RoomsIndexPage
